import { NAV_BAR_HEIGHT } from 'consts'

export function getViewportDimension (d, fallback = 0) {
  if (typeof window !== 'undefined' && window.document) {
    if (d === 'vw' || d === 'width') {
      return Math.max(
        document.documentElement.clientWidth || fallback,
        window.innerWidth || fallback
      )
    } else if (d === 'vh' || d === 'height') {
      return Math.max(
        document.documentElement.clientHeight || fallback,
        window.innerHeight || fallback
      )
    } else {
      throw Error('Not a valid viewport dimension')
    }
  } else {
    return fallback
  }
}

export const getHeightTo = ({ selector, showLogger }) => {
  if (showLogger) console.log(selector)
  const initialFilterTop =
    document.querySelector(selector)?.getBoundingClientRect()?.top || 0

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop

  const clientTop = docEl.clientTop || body.clientTop || 0

  if (showLogger)
    console.log(
      `Initial Filter Top: ${initialFilterTop}\nScroll Top: ${scrollTop}\nClient Top: ${clientTop}`
    )

  return initialFilterTop + scrollTop - clientTop
}

// primary use: wait for element loaded via js script
export function waitForElement (selector, doc = document, all = false) {
  return new Promise((resolve) => {
    if (doc.querySelector(selector)) {
      return all
        ? resolve(doc.querySelectorAll(selector))
        : resolve(doc.querySelector(selector))
    }

    const observer = new MutationObserver((mutations) => {
      if (doc.querySelector(selector)) {
        all
          ? resolve(doc.querySelectorAll(selector))
          : resolve(doc.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(doc.body, {
      childList: true,
      subtree: true,
    })
  })
}

const getTargetOffset = ({ hash, offsetY }) => {
  const id = window.decodeURI(hash.replace('#', ''))
  if (id !== '') {
    const element = document.getElementById(id)
    if (element) {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      const computedStyles = window.getComputedStyle(element)
      const scrollMarginTop =
        computedStyles.getPropertyValue('scroll-margin-top') ||
        computedStyles.getPropertyValue('scroll-snap-margin-top') ||
        '0px'

      return (
        element.getBoundingClientRect().top +
        scrollTop -
        parseInt(scrollMarginTop, 10) -
        clientTop -
        offsetY
      )
    }
  }
  return null
}

export const scrollOnInitialLoad = ({ verticalOffset = 0 }) => {
  requestAnimationFrame(() => {
    const offset = getTargetOffset({
      hash: window.location.hash,
      offsetY: verticalOffset,
    })
    if (offset !== null) {
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: 'smooth',
      })
    }
  })
}

const injectStylesToIframe = ({ iframe, styles }) => {
  const innerDoc = iframe?.contentDocument
  if (innerDoc?.head) {
    innerDoc.head.innerHTML =
      innerDoc.head.innerHTML + `<style>${styles}</style>`
  }
}

export const injectStyles = ({ iframe, styles, selector }) => {
  if (iframe) {
    injectStylesToIframe({ iframe, styles })
  } else {
    injectStylesToIframe({ iframe: document.querySelector(selector), styles })
  }
}

export function isScrolledIntoView ({ element, fullyVisible = false }) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom

  // Only completely visible elements return true:
  const isFullyVisible = elemTop >= 0 && elemBottom <= window.innerHeight

  // TODO: could be specific for mobile and use MOBILE_NAV_BAR_HEIGHT, but NAV_BAR_HEIGHT is fine for now
  const isPartiallyVisible =
    elemTop < window.innerHeight - NAV_BAR_HEIGHT && elemBottom >= 0

  if (fullyVisible) {
    return isFullyVisible
  } else {
    return isPartiallyVisible
  }
}
