import Analytics from '@anvilco-pkg/customer-data/segment/client'
import { NAV_BAR_HEIGHT, MOBILE_NAV_BAR_HEIGHT } from 'consts'
import { pixels } from 'media'

// dunno why import doesn't work for ui helpers... but this is fine for now
const helpers = require('@anvilco-pkg/ui/helpers')

// NOTE: this file should NOT import any React components.
// this file is lower level than regular React code, and should be
// treated as if React is unavailable
export const onRouteUpdate = ({ location, prevLocation }) => {
  const { isInEu, getCookie, COOKIE_CONSENT_NAME, YES_CONSENT } = helpers

  const notInEu = !isInEu()
  const inEuAndConsented =
    isInEu() && getCookie(COOKIE_CONSENT_NAME) === YES_CONSENT
  if ((notInEu || inEuAndConsented) && window.analytics && Analytics.page) {
    Analytics?.page()
  }

  // if not at the top of the page, scroll to top
  if (window.scrollY) {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  
  if (prevLocation) {
    // if there is a hash, scroll to it. only do this on route change
    const id = removeIdFromEl()
    scrollOnLoadOrRouteChange(id)
  }
}

export const onClientEntry = () => {
  const id = removeIdFromEl()
  window.onload = () => {
    scrollOnLoadOrRouteChange(id)
  }
}

function removeIdFromEl() {
  const hash = window.location.hash

  let id
  if (hash) {
    // remove the hash from the URL
    const element = document.querySelector(hash)
    // optional chaining shouldnt be needed here but it is
    // so whatever
    id = element?.id
    if (id) element.id = `${id}-scroll`
  }

  return id
}

function scrollOnLoadOrRouteChange(id) {
  if (id) {
    const hash = window.location.hash
    // get viewport width
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )

    // test-drive doesn't work on mobile and tablet, so redirect to the
    // correct page (which tells them to go to the desktop)
    if (hash.includes('test-drive') && vw < pixels.desktop) {
      // remove -test-drive from hash
      const pageSlug = hash.replace('-test-drive', '').replace('#', '')
      window.location.href = `/test-drive/${pageSlug}/`
      return
    }

    // this is a hacky way to scroll to the correct section, but works well
    // we are doing this to let any iframes/lazy loaded content load first,
    // THEN scroll to the correct section.
    // we immediately remove the hash to kill the jump, then add it back
    // after we perform the scroll.
    setTimeout(() => {
      // `${id}-scroll` is the id we set above and has the real element, but
      // in gatsby develop, the element is not found. So we try both.
      const element =
        document.getElementById(id) || document.getElementById(`${id}-scroll`)
      const top = element?.getBoundingClientRect().top
      window.scrollTo({
        top:
          top - (vw <= pixels.desktop ? MOBILE_NAV_BAR_HEIGHT : NAV_BAR_HEIGHT),
        behavior: 'smooth',
      })
      element.id = id
    }, 100)
  } else {
    // if no hash, scroll to top - this is browser default,
    // but let's make sure it's correct while we're here
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }
}
