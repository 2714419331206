// 'Form' is any form on the site. It is different from our product, 'Webforms'
// and different from our 'Form Templates' found in the Form Library, which are just
// our products 'Workflows' and 'PDF Templates'
export const EVENT_FORM_SUBMITTED = 'Form Submitted'
export const EVENT_OUTBOUND_LINK_CLICKED = 'Outbound Link Clicked'
export const EVENT_LOGIN_CLICKED = 'Login Clicked'
export const EVENT_SIGNUP_FORM_VIEWED = 'Signup Form Viewed'
export const EVENT_SIGNUP_FORM_INTERACTED = 'Signup Form Interacted'
export const EVENT_MAILTO_LINK_CLICKED = 'Mailto Link Clicked'
export const EVENT_TELEPHONE_LINK_CLICKED = 'Telephone Link Clicked'
export const EVENT_CALL_TO_ACTION_CLICKED = 'Call to Action Clicked'

// TODO: these events
export const EVENT_PDF_LINK_CLICKED = 'PDF Link Clicked' // TODO: PDF link clicked? download clicked?
// TODO: vide action events

// TODO: derek's own ideas - ask: is it worth firing different events?
// maybe/probably page calls + attributes in each CTA event are enough for reporting?
export const EVENT_DOCUMENT_FORM_CLICKED = 'Document Form Clicked'
export const EVENT_DOCUMENT_FORM_TEMPLATE_COPIED_INTO_ACCOUNT =
  'Document Form Template Copied Into Account'

export const EVENT_TEST_DRIVE_UPLOADED = 'Test Drive Uploaded'
export const EVENT_TEST_DRIVE_BANNER_OPENED = 'Test Drive Banner Opened'
export const EVENT_TEST_DRIVE_BANNER_CLICKED = 'Test Drive Banner Clicked'
export const EVENT_TEST_DRIVE_SECTION_VIEWED = 'Test Drive Section Viewed'
export const EVENT_TEST_DRIVE_SECTION_CLICKED = 'Test Drive Section Clicked'
export const EVENT_TEST_DRIVE_PDF_RESULTS_VIEWED =
  'Test Drive PDF Results Viewed'
export const EVENT_TEST_DRIVE_ETCH_EDITOR_VIEWED =
  'Test Drive Etch Editor Viewed'
